<template>
  <div class="center">
    <!--列表label-->
    <head-layout
      head-title="自定义表单模板"
      :head-btn-options="headBtnOptions"
      @head-template="goAddTemplate"
    ></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="option"
      :table-data="tableData"
      :table-loading="loading"
      :data-total="page.total"
      :grid-row-btn="gridRowBtn"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
    >
      <template #customBtn="{ row }">
        <el-button type="text" size="small" @click="goDetails(row)"
          >查看详情
        </el-button>
        <!-- <el-button type="text" size="small">模板制作 </el-button> -->
        <el-button
          type="text"
          size="small"
          @click="goEdit(row)"
          :disabled="row.paModelStatus === '1'"
          v-if="row.busiCode !== 'old'"
          >模板修订
        </el-button>
      </template>
    </grid-layout>
  </div>
</template>

    <script>
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { pamodel } from "@/api/formTemplate/index.js";
import { deptChildTree } from "@/api/reportTemplate";
export default {
  components: { HeadLayout, GridLayout },
  data() {
    return {
      loading: false,
      tableData: [],
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0
      },
      option: {
        menuWidth: 200,
        selection: false,
        menu: true,
        editBtn: false,
        delBtn: false,
        index: true,
        indexLabel: "序号",
        column: [
          {
            label: "模板编号",
            prop: "paModelInfoCode",
            overHidden: true,
          },
          {
            label: "模板名称",
            prop: "paModelInfoName",
            overHidden: true,
            width: 220,
          },
          {
            label: "模板类型",
            prop: "paModelTypeName",
            overHidden: true,
          },
          {
            label: "模板适用单位",
            prop: "orgName",
            overHidden: true,
          },
          {
            label: "模板适用单位",
            overHidden: true,
            searchLabelWidth: 110,
            hide: true,
            prop: "orgId",
            type: "tree",
            dicData: [],
            props: {
              label: "deptName",
              value: "id",
            },
          },
          {
            label: "制作时间",
            prop: "crtTime",
            overHidden: true,
            type: "datetime",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
          },
          {
            label: "最新更新时间",
            prop: "updTime",
            overHidden: true,
            type: "datetime",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
          },
          {
            label: "有效开始时间",
            prop: "startTime",
            type: "datetime",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            overHidden: true,
          },
          {
            label: "有效结束时间",
            prop: "endTime",
            type: "datetime",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            overHidden: true,
          },
          {
            label: "模板状态",
            prop: "paModelStatus",
            type: "select",
            overHidden: true,
            dicData: [
              {
                code: "1",
                name: "已启用",
              },
              {
                code: "0",
                name: "已停用",
              },
            ],
            props: {
              label: "name",
              value: "code",
            },
          },
        ],
      },
      searchColumns: [
        {
          label: "模板编号",
          prop: "paModelInfoCode",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "模板编号",
        },
        {
          label: "模板名称",
          prop: "paModelInfoName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "模板名称",
        },
        {
          label: "模板适用单位",
          prop: "orgId",
          span: 4,
          type: "tree",
          dicData: [],
          props: {
            label: "deptName",
            value: "id",
          },
          placeholder: "请选择模板适用单位",
        },
        {
          label: "模板状态",
          prop: "paModelStatus",
          span: 4,
          maxlength: 20,
          minlength: 2,
          type: "select",
          dataType: "string",
          dicData: [
            {
              code: "1",
              name: "已启用",
            },
            {
              code: "0",
              name: "已停用",
            },
          ],
          props: {
            label: "name",
            value: "code",
          },
          placeholder: "请选择模板状态",
        },
      ],
    };
  },
  computed: {
    headBtnOptions() {
      let buttonBtn = [
        {
          label: "模板制作",
          emit: "head-template",
        },
      ];
      return buttonBtn;
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      //新增按钮
      return [];
    },
  },
  methods: {
    getcomponylist() {
      deptChildTree().then((res) => {
        let treeData = [res.data.data];
        const column = this.findObject(this.option.column, "orgId");
        column.dicData = treeData;
        const searchTree = this.findObject(this.searchColumns, "orgId");
        searchTree.dicData = treeData;
      });
    },
    goDetails(row) {
        this.$router.push({
          path: `/formTemplate/newDetail`,
          query: { moduleId: row.paModelInfoId, busiObj: row.busiCode },
        });
    },
    goEdit(row) {
      this.$router.push({
          path: `/formTemplate/addTemplate`,
          query: { moduleId: row.paModelInfoId },
        });
    },
    goAddTemplate() {
      this.$router.push({
        path: `/formTemplate/addTemplate`,
        query: {},
      });
    },
    // 列表查询 add by steve
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      params.paModelType = 1;
      pamodel(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      )
        .then((res) => {
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getcomponylist();
  },
  // 初始化数据 add by steve
  mounted() {
    this.onLoad(this.page, {});
  },
};
</script>
